<template>
  <section id="appbar">
    <v-app-bar
      fixed
      elevate-on-scroll
      :color="scrollPosition < 10 ? 'transparent' : 'white'"
      v-scroll="onScroll"
    >
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-card 
          class="d-flex justify-space-between align-center"
          color="transparent"
          tile
          flat
          width="100%"
        >
          <router-link to="/">
            <v-img
              :src="scrollPosition < 10 
                ? require('@/assets/images/logos/logo_side_round_white_w200.png') 
                : require('@/assets/images/logos/logo_side_round_w200.png')"
              width="200"
              alt="New Wave Lending Group"
            />
          </router-link>
          <v-card
            color="transparent"
            tile
            flat
          >
            <v-btn
              v-for="menu in menus" 
              text
              rounded
              class="mx-1"
              :color="scrollPosition < 10  ? 'white' : 'black'"
              
              :key="`menu-${menu.title}`"
              :to="menu.path"
            >
              {{ menu.title }}
            </v-btn>
            <v-btn
              rounded
              outlined
              class="mx-1"
              color="primary"
              elevation="0"
              target="_blank"
              href="https://partner.newwavelending.com/sign-in"
            >
              <v-icon
                class="mr-1"
                small
                :color="scrollPosition < 10 ? 'white' : 'primary'"
              >
                mdi-account-lock
              </v-icon>
              <span :class="scrollPosition < 10 ? 'white--text' : 'primary--text'">Sign In</span>
            </v-btn>
          </v-card>
        </v-card>
      </template>

      <template v-else>
        <v-card 
          class="d-flex justify-space-between align-center"
          color="transparent"
          tile
          flat
          width="100%"
        >
          <v-btn
            @click="drawer.open = true"
            color="white"
            icon
          >
            <v-icon
              x-large
            >
              mdi-menu
            </v-icon>
          </v-btn>
          
          <router-link to="/">
            <v-img
              :src="scrollPosition < 10 
                ? require('@/assets/images/logos/logo_side_round_white_w200.png') 
                : require('@/assets/images/logos/logo_side_round_w200.png')"
              width="200"
              alt="New Wave Lending Group"
            />
          </router-link>
        
          <v-btn
            href="https://partner.newwavelending.com/sign-in"
            target="_blank"
            color="white"
            icon
          >
            <v-icon
              large
            >
              mdi-account
            </v-icon>
          </v-btn>
        </v-card>
      </template>
    </v-app-bar>

    <!-- DRAWER MOBILE MENU -->
    <template v-if="!$vuetify.breakpoint.mdAndUp">
      <v-navigation-drawer 
        app 
        temporary 
        v-model="drawer.open"
      >
        <v-list-item>
          <v-list-item-content>
            <!-- MOBILE LOGO -->
            <router-link to="/">
              <v-img
                alt="New Wave Lending Group"
                class="shrink mr-2"
                contain
                :src="require('@/assets/images/logos/logo_side_round_w200.png')"
                min-width="200"
                width="100"
              />
            </router-link>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <!-- MOBILE MAIN MENU -->
        <v-list dense nav>
          <v-list-item
            v-for="menu in menus"
            :key="`mobile-menu-${menu.title}`"
            :to="menu.path"
            link
          >
            <v-list-item-icon>
              <v-icon 
                v-text="menu.icon"
              ></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ menu.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <!-- MOBILE SUB MENU -->
        <v-list dense nav>
          <v-list-item
            link
            target="_blank"
            href="https://partner.newwavelending.com/sign-in"
          >
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Sign In</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-btn
              rounded
              color="primary"
              dark
              medium
              class="ma-2"
              @click="drawer.open = false;"
            >
              BECOME OUR PARTNER
            </v-btn>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </template>
  </section>
</template>

<!-- <style>
  #appbar .theme--light.v-btn--active:hover::before, 
  #appbar .theme--light.v-btn--active::before {
    opacity: 1;
    border-bottom: 2px solid #000;
  }
</style> -->

<script>
export default {
  data(){
    return {
      menus: [
        {
          title: "About",
          path: "/about-us",
          icon: "mdi-office-building",
        },
        {
          title: "Products",
          path: "/products",
          icon: "mdi-file-powerpoint",
        },
        {
          title: "Resources",
          path: "/resources",
          icon: "mdi-text-box-multiple",
        },        
        {
          title: "Settlement Agent Portal",
          path: "/settlement-agent-portal",
          icon: "mdi-file-upload",
        },
        {
          title: "Licensed States",
          path: "/licensing",
          icon: "mdi-circle",
        },
        {
          title: "Contact",
          path: "/Contact",
          icon: "mdi-send-outline",
        },
      ],
      drawer: {
        open: false,
      },
      scrollPosition: 0,
    }
  },
  methods:{
    onScroll(e) {
      if (typeof window === "undefined") {
        return;
      }

      this.scrollPosition = window.pageYOffset || e.target.scrollTop || 0;
    },
  }
}
</script>