<template>
  <v-card :color="dark ? 'transparent' : 'lightgrey'" flat tile>
    <v-container>
      <v-card
        class="d-flex justify-center align-center my-1"
        color="transparent"
        flat
        tile
      >
        <v-card class="ma-5" width="150" color="transparent" flat tile>
          <router-link to="/">
            <v-img
              :src="
                dark
                  ? require('@/assets/images/logos/logo_circle_white_w150.png')
                  : require('@/assets/images/logos/logo_circle_w150.png')
              "
            />
          </router-link>
        </v-card>
        <v-card
          :class="dark ? 'white--text ma-5' : 'ma-5'"
          color="transparent"
          flat
          tile
        >
          <div>Corporate Office</div>
          <a
            :href="$store.getters.googleMapLink"
            :class="
              dark
                ? 'white--text text-decoration-none'
                : 'black--text text-decoration-none'
            "
            target="_blank"
          >
            <div>
              {{ $store.state.company.street1 }}
              {{ $store.state.company.street2 }}
            </div>
            <div>
              {{ $store.state.company.city }}, {{ $store.state.company.state }}
              {{ $store.state.company.zip }}
            </div>
          </a>
          <div>
            <a
              :href="`tel:+${$store.state.company.tel}`"
              :class="
                dark
                  ? 'white--text text-decoration-none'
                  : 'black--text text-decoration-none'
              "
            >
              Office:
              {{ $store.state.company.tel | VMask($store.state.mask.phone) }}
            </a>
            / Fax:
            {{ $store.state.company.fax | VMask($store.state.mask.phone) }}
          </div>
          <div>
            <a
              :href="`${$store.state.nmlsconsumeraccess}/${$store.state.company.nmls}`"
              :class="
                dark
                  ? 'text-decoration-none white--text'
                  : 'text-decoration-none black--text'
              "
              target="_blank"
            >
              NMLS #: {{ $store.state.company.nmls }}
            </a>
          </div>
          <div>
            <a
              :href="`${$store.state.nmlsconsumeraccess}/${$store.state.company.nmls}`"
              :class="
                dark
                  ? 'text-decoration-none white--text'
                  : 'text-decoration-none black--text'
              "
              target="_blank"
            >
              www.nmlsconsumeraccess.org
            </a>
          </div>
        </v-card>
      </v-card>
      <v-card class="text-center" color="transparent" flat tile>
        <v-card
          flat
          tile
          max-width="600"
          color="transparent"
          :class="`d-inline-flex mx-auto ${dark ? 'white--text' : ''}`"
        >
          <v-img
            :src="
              dark
                ? require('@/assets/images/logos/opportunity_house_white.svg')
                : require('@/assets/images/logos/opportunity_house_black.svg')
            "
            width="42"
            height="auto"
            class="mx-4"
          ></v-img>
          <span class="text-body-2">
            Equal Housing Lender. © {{ new Date().getFullYear() }} New Wave
            Lending Inc. Some products may not be available in all states. This
            is not a commitment to lend. Restrictions apply.
          </span>
        </v-card>
        <v-card class="text-uppercase" flat tile color="transparent">
          <span v-for="(m, index) in siteMaps" :key="`bottom-menu-${m.title}`">
            <router-link
              :to="m.path"
              :class="
                dark
                  ? 'text-decoration-none white--text'
                  : 'text-decoration-none black--text'
              "
            >
              {{ m.title }}
              <span v-if="index < siteMaps.length - 1" class="mx-3">|</span>
            </router-link>
          </span>
          <!-- <span>
            <a
              href="https://partner.newwavelending.com/sign-in"
              target="_blank"
              :class="dark ? 'text-decoration-none white--text' : 'text-decoration-none black--text'"
            >
              Sign-In
            </a>
          </span> -->
        </v-card>
      </v-card>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: {
    dark: Boolean,
  },
  data() {
    return {
      siteMaps: [
        {
          title: "Licensed States",
          path: "/licensing",
        },
        {
          title: "Career",
          path: "/career",
        },
        {
          title: "Contact Us",
          path: "/Contact",
        },
        {
          title: "Financial hardships",
          path: "/financial-hardships",
        },
        // {
        //   title: "Privacy",
        //   path: "/",
        // },
        // {
        //   title: "Policies",
        //   path: "/",
        // },
        // {
        //   title: "Security",
        //   path: "/",
        // },
        // {
        //   title: "Site Map",
        //   path: "/",
        // }
      ],
    };
  },
};
</script>
