import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Home from "@/views/Home.vue";
import Page404 from "@/views/Page404.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: "Home - " + store.state.company.name,
    },
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    meta: {
      title: "About - " + store.state.company.name,
    },
    component: () => import("@/views/About.vue"),
  },

  {
    path: "/about-us",
    name: "AboutUs",
    meta: {
      title: "About Us - " + store.state.company.name,
    },
    component: () => import("@/views/About.vue"),
  },
  {
    path: "/career",
    name: "Career",
    meta: {
      title: "Career - " + store.state.company.name,
    },
    component: () => import("@/views/Career.vue"),
  },
  {
    path: "/career-detail",
    name: "CareerDetail",
    meta: {
      title: "Career Detail - " + store.state.company.name,
    },
    component: () => import("@/views/CareerDetail.vue"),
  },
  {
    path: "/contact-1",
    name: "Contact-1",
    meta: {
      title: "Contact-1 - " + store.state.company.name,
    },
    component: () => import("@/views/Contact.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    meta: {
      title: "Contact - " + store.state.company.name,
    },
    component: () => import("@/views/Contact.vue"),
  },
  {
    path: "/financial-hardships",
    name: "Financial Hardships",
    meta: {
      title: "Financial Hardships - " + store.state.company.name,
    },
    component: () => import("@/views/FinancialHardShips.vue"),
  },
  {
    path: "/settlement-agent-portal",
    name: "SettlementAgentPortal",
    meta: {
      title: "Settlement Agent Portal - " + store.state.company.name,
    },
    component: () => import("@/views/SettlementAgentPortal.vue"),
  },
  {
    path: "/products",
    name: "Products",
    meta: {
      title: "Products - " + store.state.company.name,
    },
    component: () => import("@/views/Products.vue"),
  },
  {
    path: "/resources",
    name: "Resources",
    meta: {
      title: "Resources - " + store.state.company.name,
    },
    component: () => import("@/views/Resources.vue"),
  },
  {
    path: "/licensing",
    name: "Licensing",
    meta: {
      title: "Licensing - " + store.state.company.name,
    },
    component: () => import("@/views/Licensing.vue"),
  },
  {
    path: "/clp",
    name: "CLP",
    meta: {
      title: "CLP - " + store.state.company.name,
    },
    component: () => import("@/views/CLP.vue"),
  },
  {
    path: "*",
    name: "Page404",
    meta: {
      title: "Page Not Found - " + store.state.company.name,
    },
    component: Page404,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

/*eslint-disable */
router.beforeEach((to, from, next) => {
  // Set meta title
  document.title = to.meta.title;
  next();
});
/*eslint-enable */

export default router;
