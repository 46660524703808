import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0B8EC4',
        secondary: '#335BFC',
        accent: '#8091a1',
        error: '#E53935',
        info: '#7460ee',
        success: '#7ace4c',
        warning: '#ffbb44',
        background: "#ffffff",
        bluesky: "#eaeff4",
        rose: "#fb9678",
        grey: "#CCCCCC",
        black: "#333333",
        lightgrey: "#F4F4F4",
        beige: "#FBF9F9",
        readonly: "#dee2e6",
      },
      dark: {
        primary: '#0B8EC4',
        secondary: '#335BFC',
        accent: '#8091a1',
        error: '#E53935',
        info: '#7460ee',
        success: '#7ace4c',
        warning: '#ffbb44',
        background: "#ffffff",
        bluesky: "#eaeff4",
        rose: "#fb9678",
        grey: "#CCCCCC",
        black: "#333333",
        lightgrey: "#F4F4F4",
        beige: "#FBF9F9",
        readonly: "#dee2e6",
      },
    },
  },
});
