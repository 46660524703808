<template>
  <section>
    <div
      style="width: 100%; height: 10vh; background: linear-gradient(180deg, rgba(102,102,102,1) 0%, rgba(255,255,255,1) 100%);"
    ></div>
    <v-container
      class="d-flex justify-center align-center flex-column"
      style="height: 90vh; max-width: 600px;"
    >
      <div class="my-10" style="width: 400px;">
        <v-img alt="Page 404" src="@/assets/images/etc/page_404_error.png" />
      </div>
      <div class="text-h4">
        There is no page.
      </div>
      <div class="text-h4">
        you are looking for.
      </div>
      <div class="my-6 text-center">
        <div class="font-weight-light">
          Invalid access or the requested page could not be found.
        </div>
        <div class="font-weight-light">
          Please double check that the address on the page you entered is
          correct.
        </div>
      </div>
      <v-btn
        to="/"
        class="px-10 py-6"
        color="grey lighten-2"
        elevation="0"
        rounded
        large
      >
        Go Home
      </v-btn>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "Page404",
};
</script>
