import LookUpService from "../../services/feature/lookup.service";

export const lookUpApi = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    getMstCodeVM({ commit }, codeType) {
      return LookUpService.getMstCodeVM(codeType).then(
        (resp) => {
          commit("dummy", "getMstCodeVM - Success");
          return Promise.resolve(resp);
        },
        (error) => {
          commit("dummy", "getMstCodeVM - Error");
          return Promise.reject(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );
        }
      );
    },

    getLoanStatusPipeline({ commit }) {
      return LookUpService.getLoanStatusPipeline().then(
        (resp) => {
          commit("dummy");
          return Promise.resolve(resp);
        },
        (error) => {
          commit("dummy");
          return Promise.reject(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );
        }
      );
    },

    getHashTableMstCode() {
      return LookUpService.getHashTableMstCode().then(
        (resp) => {
          return Promise.resolve(resp);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    getHashTableOrganization() {
      return LookUpService.getHashTableOrganization().then(
        (resp) => {
          return Promise.resolve(resp);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    getBrokerCompPlan({ commit }, { organizationId, loanNumber }) {
      return LookUpService.getBrokerCompPlan(organizationId, loanNumber).then(
        (resp) => {
          commit("dummy", "getBrokerCompPlan - Success");
          return Promise.resolve(resp);
        },
        (error) => {
          commit("dummy", "getBrokerCompPlan - error");
          return Promise.reject(error.response);
        }
      );
    },

    getCompensationPlan({ commit }, originationChannel) {
      return LookUpService.getCompensationPlan(originationChannel).then(
        (resp) => {
          commit("dummy", "getCompensationPlan - Success");
          return Promise.resolve(resp);
        },
        (error) => {
          commit("dummy", "getCompensationPlan - error");
          return Promise.reject(error.response);
        }
      );
    },

    getProgramLockDays(
      { commit },
      { loanProgramId, loanStatus, originationChannel }
    ) {
      return LookUpService.getProgramLockDays(
        loanProgramId,
        loanStatus,
        originationChannel
      ).then(
        (resp) => {
          commit("dummy", "getProgramLockDays - Success");
          return Promise.resolve(resp);
        },
        (error) => {
          commit("dummy", "getProgramLockDays - error");
          return Promise.reject(error.response);
        }
      );
    },
  },
  mutations: {
    dummy(state, obj) {
      console.log(obj);
    },
  },
};
