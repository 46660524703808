import axios from "axios";
// import authHeader from "../auth-header";
import publicHeader from "../public-header";

const API_URL = `${process.env.VUE_APP_API_BASE_URL}/api/Feature/Public`;

class PublicService {
  validateCencusTract({ stateCode, countyCode, censusTract }) {
    return axios
      .get(
        `${API_URL}/Validate/CencusTract?stateCode=${stateCode}&countyCode=${countyCode}&censusTract=${censusTract}`,
        {
          headers: publicHeader(),
        }
      )
      .then((response) => {
        return response;
      });
  }

  parseCencusTract(residence) {
    return axios
      .post(`${API_URL}/Parse/CencusTract`, residence, {
        headers: publicHeader(),
      })
      .then((response) => {
        return response;
      });
  }

  getCityAndStateByZipcode(zipcode) {
    return axios
      .get(`${API_URL}/CityAndState?zipcode=${zipcode}`, {
        headers: publicHeader(),
      })
      .then((response) => {
        return response;
      });
  }
}

export default new PublicService();
