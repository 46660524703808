<template>
  <div class="text-center">
    <v-dialog v-model="$store.state.statusDialog.show" persistent width="520">
      <v-card v-if="$store.state.statusDialog.status" class="text-center" outlined :elevation="0">
        <v-card-subtitle class="py-4">
          <v-icon
            :color="$store.state.statusDialog.status"
            size="90"
          >
            {{ getIcon($store.state.statusDialog.status) }}
          </v-icon>
          <div :class="`text-h5 text-capitalize ${$store.state.statusDialog.status}--text`" v-html="$store.state.statusDialog.status"></div>
        </v-card-subtitle>
        <v-card-text class="my-0">
          <div class="text-h6" v-html="$store.state.statusDialog.message.replaceAll('\r\n', '<br />')"></div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            min-width="100"
            class="mx-auto"
            :color="$store.state.statusDialog.status"
            @click="$store.commit('CLOSE_STATUS_DIALOG')"
            large
            rounded
            outlined
            :elevation="0"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  methods: {
    getIcon(status){
      if(status === "success"){
        return "mdi-check-circle-outline";
      }
      else if(status === "error"){
        return "mdi-cancel";
      }
      else if(status === "info"){
        return "mdi-information-outline";
      }
      else if (status === "warning"){
        return "mdi-alert";
      }
      else {
        return "mdi-help-circle-outline";
      }
    }
  }
};
</script>
