<template>
  <section id="banner">
    <swiper class="swiper" :options="swiperOption" style="width: 100%; height: 100vh; background-color: #444;">
      <swiper-slide>
        <v-img 
          position="center center"
          :src="require('@/assets/images/heros/bigstock-Big-Blue-Ocean-Wave-Background-357723107.png')"
          height="100%"
          :gradient="graident"
        >
          <v-card
            class="d-flex flex-column justify-space-around"
            color="transparent"
            height="100%"
            tile
            flat
          >
            <v-card hidden></v-card>
            <v-card
              color="transparent"
              flat
              tile
            >
              <v-card
                class="d-flex justify-center justify-md-end align-center flex-column text-center px-4"
                color="transparent"
                flat
                height="400"
              >
                <div class="text-h4 text-md-h2 white--text text-uppercase font-weight-bold font-secondary">
                  <p>THE NEW WAVE OF</p>
                  <p>LENDING IS HERE</p>
                </div>
                <div class="text-body-2 text-md-h5 white--text font-weight-light font-secondary" style="max-width: 1000px;">
                  We are constantly growing our product offering and greater financing flexibility.
                </div>
                <v-btn 
                  color="primary lighten-2"
                  outlined
                  rounded
                  class="mt-5 outlined-2"
                  to="/products"
                >
                  <span class="white--text">Learn our products</span>
                </v-btn>
              </v-card>
            </v-card>
            <v-card
              color="transparent"
              flat
              tile
            >
              <v-card 
                class="d-none d-sm-none d-md-none d-lg-flex d-xl-flex justify-center"
                color="transparent"
                flat
              >
                <v-card
                  v-for="item in bannerSubContents"
                  :key="`page1-${item.title}`"
                  width="320"
                  color="transparent"
                  class="mx-8 white--text"
                  flat
                >
                  <v-btn
                    color="primary lighten-2"
                    outlined
                    label
                    class="pa-0"
                    style="border-width: 2px;"
                    :to="item.link"
                  >
                    <v-icon
                      color="white"
                    >
                    {{ item.icon }}
                    </v-icon>
                  </v-btn>
                  <div class="text-body-1 text-uppercase font-weight-bold my-2 font-secondary">
                    {{ item.title }}
                  </div>
                  <div class="grey--text">
                    {{ item.body }}
                  </div>
                </v-card>
              </v-card>
            </v-card>
          </v-card>
        </v-img>
      </swiper-slide>
      <swiper-slide>
        <v-img 
          position="center center"
          :src="require('@/assets/images/heros/Mask-Group-481.png')"
          height="100%"
          :gradient="graident"
        >
          <v-card
            class="d-flex flex-column justify-space-around"
            color="transparent"
            height="100%"
            tile
            flat
          >
            <v-card hidden></v-card>
            <v-card
              color="transparent"
              flat
              tile
            >
              <v-card
                class="d-flex justify-center justify-md-end align-center flex-column text-center px-4"
                color="transparent"
                flat
                height="400"
              >
                <div class="text-h4 text-md-h2 white--text text-uppercase font-weight-bold font-secondary">
                  <p>Fulfill your Client’s Dream</p>
                  <p>with New Wave</p>
                </div>
                <div class="text-body-2 text-md-h5 white--text font-weight-light" style="max-width: 1000px;">
                  We have a variety of loan products to meet all your client's needs, on time closings to make the transaction hassle free so you can focus on what's more important.
                  New Wave has your back!
                </div>
                <v-btn 
                  color="primary lighten-2"
                  outlined
                  rounded
                  class="mt-5 outlined-2"
                  to="/products"
                >
                  <span class="white--text">Learn our products</span>
                </v-btn>
              </v-card>
            </v-card>
            <v-card
              color="transparent"
              flat
              tile
            >
              <v-card 
                class="d-none d-sm-none d-md-none d-lg-flex d-xl-flex justify-center"
                color="transparent"
                flat
              >
                <v-card
                  v-for="item in bannerSubContents"
                  :key="`page1-${item.title}`"
                  width="320"
                  color="transparent"
                  class="mx-8 white--text"
                  flat
                >
                  <v-btn
                    color="primary lighten-2"
                    outlined
                    label
                    class="pa-0"
                    style="border-width: 2px;"
                    :to="item.link"
                  >
                    <v-icon
                      color="white"
                    >
                    {{ item.icon }}
                    </v-icon>
                  </v-btn>
                  <div class="text-body-1 text-uppercase font-weight-bold my-2 font-secondary">
                    {{ item.title }}
                  </div>
                  <div class="grey--text">
                    {{ item.body }}
                  </div>
                </v-card>
              </v-card>
            </v-card>
          </v-card>
        </v-img>
      </swiper-slide>
      <swiper-slide>
        <v-img 
          position="center center"
          :src="require('@/assets/images/heros/Mask-Group-480.png')"
          height="100%"
          :gradient="graident"
        >
          <v-card
            class="d-flex flex-column justify-space-around"
            color="transparent"
            height="100%"
            tile
            flat
          >
            <v-card hidden></v-card>
            <v-card
              color="transparent"
              flat
              tile
            >
              <v-card
                class="d-flex justify-center justify-md-end align-center flex-column text-center px-4"
                color="transparent"
                flat
                height="400"
              >
                <div class="text-h4 text-md-h2 white--text text-uppercase font-weight-bold font-secondary">
                  <p>Find your Loan here</p>
                </div>
                <div class="text-body-2 text-md-h5 white--text font-weight-light" style="max-width: 1000px;">
                  Whether your borrowers want to buy a new home, refinance their mortgage, we may be able to help. Use one of our quick & easy tools below to get started today!
                </div>
                <v-btn 
                  color="primary lighten-2"
                  outlined
                  rounded
                  class="mt-5 outlined-2"
                  to="/products"
                >
                  <span class="white--text">Learn our products</span>
                </v-btn>
              </v-card>
            </v-card>
            <v-card
              color="transparent"
              flat
              tile
            >
              <v-card 
                class="d-none d-sm-none d-md-none d-lg-flex d-xl-flex justify-center"
                color="transparent"
                flat
              >
                <v-card
                  v-for="item in bannerSubContents"
                  :key="`page1-${item.title}`"
                  width="320"
                  color="transparent"
                  class="mx-8 white--text"
                  flat
                >
                  <v-btn
                    color="primary lighten-2"
                    outlined
                    label
                    class="pa-0"
                    style="border-width: 2px;"
                    :to="item.link"
                  >
                    <v-icon
                      color="white"
                    >
                    {{ item.icon }}
                    </v-icon>
                  </v-btn>
                  <div class="text-body-1 text-uppercase font-weight-bold my-2 font-secondary">
                    {{ item.title }}
                  </div>
                  <div class="grey--text">
                    {{ item.body }}
                  </div>
                </v-card>
              </v-card>
            </v-card>
          </v-card>
        </v-img>
      </swiper-slide>
      <swiper-slide>
        <v-img 
          position="center center"
          :src="require('@/assets/images/heros/Group-1457.png')"
          height="100%"
          :gradient="graident"
        >
          <v-card
            class="d-flex flex-column justify-space-between"
            color="transparent"
            height="100%"
            tile
            flat
          >
            <v-card hidden></v-card>
            <v-card
              color="transparent"
              flat
              tile
            >
              <v-card
                class="d-flex justify-center justify-md-end align-center flex-column text-center"
                color="transparent"
                flat
                height="400"
              >
                <div class="text-h4 text-md-h2 white--text text-uppercase font-weight-bold font-secondary">
                  <p>Contact US</p>
                </div>
                <div class="text-body-2 text-md-h5 white--text font-weight-light" style="max-width: 1000px;">
                  We are standing to hear your voice.
                </div>
                <v-btn 
                  color="primary lighten-2"
                  outlined
                  rounded
                  class="mt-5 outlined-2"
                  to="/contact-1"
                >
                  <span class="white--text">Keep in touch</span>
                </v-btn>
              </v-card>
            </v-card>
            <v-card
              color="transparent"
              flat
              tile
            >
              <v-card 
                color="transparent"
                flat
              >
                <Footer :dark="true" />
              </v-card>
            </v-card>
          </v-card>
        </v-img>
      </swiper-slide>
      <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
    </swiper>
  </section>
</template>

<style lang="scss" scoped>
  $font-size-huge: 12;
  $white:#fff;
</style>

<script>
  import Footer from "@/views/layouts/Footer.vue";
  import { swiper, swiperSlide } from 'vue-awesome-swiper';
  import 'swiper/dist/css/swiper.css';

  export default {
    components: {
      swiper,
      swiperSlide,
      Footer
    },
    data(){
      return {
        swiperOption: {
          direction: 'vertical',
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          }
        },
        bannerSubContents: [
          {
            icon: "mdi-cursor-default-click-outline",
            title: "1. OUR PRODUCTS",
            body: "Find information on New Wave Wholesale Lending Conforming, and Government loan Programs. A wide array of competitive loan options. Click below for details on our complete agency, jumbo, Portfolio, and Non-QM program guidelines.",
            link: "/products",
          },
          {
            icon: "mdi-thumb-up-outline",
            title: "2. Become Approved",
            body: "Take your first step to becoming an approved broker with us and get competitive pricing.",
            link: "/contact-1",
          },
          {
            icon: "mdi-clock-check-outline",
            title: "3. FAST closing",
            body: "Simplified workflow process, ease to use real time up to the minute broker portal, and dedicated in-house underwriting team. Click below for our turn times.",            
            link: "/",
            // link: "/settlement-agent-portal",
          },
        ],
        graident: "0deg, rgba(0, 0 ,0, .7) 0%, rgba(0, 0, 0, .2) 50%, rgba(0, 0, 0, .7) 100%"
      }
    },
  }
</script>