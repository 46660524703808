import axios from "axios";
import authHeader from "../auth-header";
import publicHeader from "../public-header";

const API_URL = `${process.env.VUE_APP_API_BASE_URL}/api/Feature/LookUp`;

class LookUpService {
  getBrokerCompPlan(organizationId, loanNumber) {
    return axios
      .get(
        `${API_URL}/BrokerCompPlan?organizationId=${organizationId}&loanNumber=${loanNumber}`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      });
  }

  getCompensationPlan(originationChannel) {
    return axios
      .get(
        `${API_URL}/CompensationPlan?originationChannel=${originationChannel}`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      });
  }

  getMstCodeVM(codeType) {
    return axios
      .get(`${API_URL}/MstCodeVM?codeType=${codeType}`, {
        headers: publicHeader(),
      })
      .then((response) => {
        return response;
      });
  }

  getLoanStatusPipeline() {
    return axios
      .get(`${API_URL}/LoanStatus/Pipeline`, {
        headers: publicHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  getHashTableMstCode() {
    return axios
      .get(`${API_URL}/HashTable/MstCode`, {
        headers: publicHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  getHashTableOrganization() {
    return axios
      .get(`${API_URL}/HashTable/Organization`, {
        headers: publicHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  getProgramLockDays(
    loanProgramId,
    loanStatus = null,
    originationChannel = null
  ) {
    return axios
      .get(
        `${API_URL}/ProgramLockDays?loanProgramId=${loanProgramId}&loanStatus=${loanStatus}&originationChannel=${originationChannel}`,
        {
          headers: publicHeader(),
        }
      )
      .then((response) => {
        return response.data;
      });
  }
}

export default new LookUpService();
