var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"color":_vm.dark ? 'transparent' : 'lightgrey',"flat":"","tile":""}},[_c('v-container',[_c('v-card',{staticClass:"d-flex justify-center align-center my-1",attrs:{"color":"transparent","flat":"","tile":""}},[_c('v-card',{staticClass:"ma-5",attrs:{"width":"150","color":"transparent","flat":"","tile":""}},[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{attrs:{"src":_vm.dark
                ? require('@/assets/images/logos/logo_circle_white_w150.png')
                : require('@/assets/images/logos/logo_circle_w150.png')}})],1)],1),_c('v-card',{class:_vm.dark ? 'white--text ma-5' : 'ma-5',attrs:{"color":"transparent","flat":"","tile":""}},[_c('div',[_vm._v("Corporate Office")]),_c('a',{class:_vm.dark
              ? 'white--text text-decoration-none'
              : 'black--text text-decoration-none',attrs:{"href":_vm.$store.getters.googleMapLink,"target":"_blank"}},[_c('div',[_vm._v(" "+_vm._s(_vm.$store.state.company.street1)+" "+_vm._s(_vm.$store.state.company.street2)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.$store.state.company.city)+", "+_vm._s(_vm.$store.state.company.state)+" "+_vm._s(_vm.$store.state.company.zip)+" ")])]),_c('div',[_c('a',{class:_vm.dark
                ? 'white--text text-decoration-none'
                : 'black--text text-decoration-none',attrs:{"href":`tel:+${_vm.$store.state.company.tel}`}},[_vm._v(" Office: "+_vm._s(_vm._f("VMask")(_vm.$store.state.company.tel,_vm.$store.state.mask.phone))+" ")]),_vm._v(" / Fax: "+_vm._s(_vm._f("VMask")(_vm.$store.state.company.fax,_vm.$store.state.mask.phone))+" ")]),_c('div',[_c('a',{class:_vm.dark
                ? 'text-decoration-none white--text'
                : 'text-decoration-none black--text',attrs:{"href":`${_vm.$store.state.nmlsconsumeraccess}/${_vm.$store.state.company.nmls}`,"target":"_blank"}},[_vm._v(" NMLS #: "+_vm._s(_vm.$store.state.company.nmls)+" ")])]),_c('div',[_c('a',{class:_vm.dark
                ? 'text-decoration-none white--text'
                : 'text-decoration-none black--text',attrs:{"href":`${_vm.$store.state.nmlsconsumeraccess}/${_vm.$store.state.company.nmls}`,"target":"_blank"}},[_vm._v(" www.nmlsconsumeraccess.org ")])])])],1),_c('v-card',{staticClass:"text-center",attrs:{"color":"transparent","flat":"","tile":""}},[_c('v-card',{class:`d-inline-flex mx-auto ${_vm.dark ? 'white--text' : ''}`,attrs:{"flat":"","tile":"","max-width":"600","color":"transparent"}},[_c('v-img',{staticClass:"mx-4",attrs:{"src":_vm.dark
              ? require('@/assets/images/logos/opportunity_house_white.svg')
              : require('@/assets/images/logos/opportunity_house_black.svg'),"width":"42","height":"auto"}}),_c('span',{staticClass:"text-body-2"},[_vm._v(" Equal Housing Lender. © "+_vm._s(new Date().getFullYear())+" New Wave Lending Inc. Some products may not be available in all states. This is not a commitment to lend. Restrictions apply. ")])],1),_c('v-card',{staticClass:"text-uppercase",attrs:{"flat":"","tile":"","color":"transparent"}},_vm._l((_vm.siteMaps),function(m,index){return _c('span',{key:`bottom-menu-${m.title}`},[_c('router-link',{class:_vm.dark
                ? 'text-decoration-none white--text'
                : 'text-decoration-none black--text',attrs:{"to":m.path}},[_vm._v(" "+_vm._s(m.title)+" "),(index < _vm.siteMaps.length - 1)?_c('span',{staticClass:"mx-3"},[_vm._v("|")]):_vm._e()])],1)}),0)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }