<template>
  <v-app>
    <AppBar />
    <v-main>
      <v-fade-transition mode="out-in">
        <router-view></router-view>
      </v-fade-transition>
    </v-main>
    <StatusDialog />
    <v-overlay :value="$store.state.globalLoading" z-index="9999">
      <v-progress-circular
        :size="70"
        :width="7"
        color="grey"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import AppBar from "@/views/layouts/AppBar";
import StatusDialog from "@/components/StatusDialog";

export default {
  name: "App",

  components: {
    AppBar,
    StatusDialog,
  },

  data: () => ({
    //
  }),
};
</script>
