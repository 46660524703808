import Vue from "vue";
import Vuex from "vuex";
import { lookUpApi } from "./feature/lookup.module";
import { publicApi } from "./feature/public.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    globalLoading: false,
    statusDialog: {
      show: false,
      status: "",
      message: "",
    },
    company: {
      name: "New Wave Lending Group, Inc",
      street1: "1199 FAIRWAY DR.",
      street2: "",
      city: "CITY OF INDUSTRY",
      state: "CA",
      zip: "91789",
      lat: 33.9938648,
      lng: -117.8709932,
      tel: "6268209081",
      fax: "8008787387",
      nmls: "1649874",
    },
    mask: {
      phone: "(###) ###-####",
    },
    currentUrl: "https://newwavelending.com",
    brokerPortalUrl: "https://partner.newwavelending.com",
    nmlsconsumeraccess:
      "https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY",
    basicAuth: {
      userName: "PublicUser",
      password: "acc84ffd-76b2-4477-9365-71fa83b16129",
    },
  },
  getters: {
    companyFullAddress(state) {
      return `${state.company.street1} ${state.company.street2}, ${state.company.city}, ${state.company.state} ${state.company.zip}`;
    },
    googleMapLink(state) {
      return `https://www.google.com/maps/place/${state.company.street1} ${state.company.street2}, ${state.company.city}, ${state.company.state} ${state.company.zip}`;
    },
  },
  mutations: {
    SHOW_STATUS_DIALOG(state, modalInfo) {
      state.statusDialog.show = true;
      state.statusDialog.status = modalInfo.status;
      state.statusDialog.message = modalInfo.message;
    },
    CLOSE_STATUS_DIALOG(state) {
      state.statusDialog.show = false;
      state.statusDialog.status = "";
      state.statusDialog.message = "";
    },
    setGlobalLoading(state, open) {
      state.globalLoading = open;
    },
  },
  actions: {},
  modules: {
    lookUpApi,
    publicApi,
  },
});
