import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueMask from "v-mask";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import VueJsModal from "vue-js-modal";
import * as VueGoogleMaps from "vue2-google-maps";
import VueAnalytics from "vue-analytics";
import VueCookies from "vue-cookies";

require("vue2-dropzone/dist/vue2Dropzone.min.css");
require("@/assets/css/style.scss");

Vue.config.productionTip = false;

Vue.use(VueMask, {
  placeholders: {
    D: /\d/, // define new placeholder
    // R: /[\wа-яА-Я]/, // cyrillic letter as a placeholder
  },
});

Vue.use(VueMoment, {
  moment,
});

Vue.use(VueJsModal, {
  dialog: true,
  dynamicDefaults: {
    draggable: true,
  },
});

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCuMUbKgXDClrWE3O2RlkQISKoBzdy0Jcc", // Google API KEY generated by kyle.kim@newwavelending.com
    libraries: "places", // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    // If you want to set the version, you can do so:
    v: "3.26",
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  // then disable the following:
  installComponents: true,
});

// Google Analytics id from info@newwavelending.com
Vue.use(VueAnalytics, {
  id: "UA-201827119-1",
  router,
});

Vue.use(VueCookies, { expires: "365d" });

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
