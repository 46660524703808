import PublicService from "../../services/feature/public.service";

export const publicApi = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    validateCencusTract({ commit }, { stateCode, countyCode, censusTract }) {
      return PublicService.validateCencusTract({
        stateCode,
        countyCode,
        censusTract,
      }).then(
        (resp) => {
          commit("dummy", "validateCencusTract - Success");
          return Promise.resolve(resp);
        },
        (error) => {
          commit("dummy", "validateCencusTract - Error");
          return Promise.reject(error.response);
        }
      );
    },
    parseCencusTract({ commit }, residence) {
      return PublicService.parseCencusTract(residence).then(
        (resp) => {
          commit("dummy", "parseCencusTract - Success");
          return Promise.resolve(resp);
        },
        (error) => {
          commit("dummy", "parseCencusTract - Error");
          return Promise.reject(error.response);
        }
      );
    },
    getCityAndStateByZipcode({ commit }, zipcode) {
      return PublicService.getCityAndStateByZipcode(zipcode).then(
        (resp) => {
          commit("dummy", "getCityAndStateByZipcode - Success");
          return Promise.resolve(resp);
        },
        (error) => {
          commit("dummy", "getCityAndStateByZipcode - Error");
          return Promise.reject(error.response);
        }
      );
    },
  },
  mutations: {
    dummy(state, obj) {
      console.log(obj);
    },
  },
};
